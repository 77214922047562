<template>
    <app-base>
        <header class="border-b">
            <nuxt-link
                v-if="!isLightbox"
                class="text-button flex h-10 items-center justify-center bg-primary-500 py-2 text-lg font-medium uppercase hover:underline"
                :to="activeFacility.organization.topBarLink"
            >
                {{ activeFacility.organization.topBarText }}
            </nuxt-link>

            <div
                class="relative flex flex-col justify-center overflow-hidden bg-white"
            >
                <div>
                    <div
                        class="relative mx-0 flex items-center justify-between gap-4 px-4 py-6 md:px-7 lg:static lg:px-16 lg:py-6"
                    >
                        <div class="flex items-center gap-2">
                            <organization-facility-logo />

                            <facility-header-link />
                        </div>

                        <div
                            class="flex w-full flex-1 items-center justify-end"
                        >
                            <ul class="flex items-center">
                                <profile-buttons />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div class="flex flex-1 flex-col">
            <div class="flex flex-1 flex-col">
                <nuxt-error-boundary @error="logSomeError">
                    <slot />

                    <template #error>
                        <app-error />
                    </template>
                </nuxt-error-boundary>
            </div>

            <powered-by-footer />
        </div>
    </app-base>
</template>

<script setup>
import { provideViewer } from '~/composables/use-login'
import { provideActiveFacilityFromSlug } from '~/composables/use-active-facility'
import NuxtErrorBoundary from '#app/components/nuxt-error-boundary'
import AppError from '~/components/NotFound/AppError.vue'
import AppBase from '~/components/Layouts/AppBase.vue'
import NuxtLink from '#app/components/nuxt-link.js'
import FacilityHeaderLink from '~/components/Layouts/FacilityLayout/FacilityHeaderLink.vue'
import OrganizationFacilityLogo from '~/components/Layouts/OrganizationFacilityLogo.vue'
import ProfileButtons from '~/components/Layouts/ProfileButtons.vue'
import { useHead } from 'unhead'
import PoweredByFooter from '~/components/PoweredByFooter.vue'
import { useLayoutMode } from '~/composables/use-layout-mode'
import { computed, onBeforeUnmount, ref } from 'vue'

const { isLightbox } = useLayoutMode()

const [activeFacility, viewer] = await Promise.all([
    provideActiveFacilityFromSlug(),
    provideViewer()
])

const unmount = ref(false)
const headStyle = computed(() => {
    if (unmount.value) {
        return []
    }

    return activeFacility.value.branding.styleString
})

useHead({
    title: activeFacility.value.title + ' | Checkout',
    style: headStyle
})

function logSomeError(error) {
    console.error(error)
}

onBeforeUnmount(() => {
    // Clear styles when leaving the component
    unmount.value = true
})
</script>
