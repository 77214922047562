<template>
    <nuxt-link
        :to="facilityHomeLink(activeFacility.slug)"
        class="flex items-center gap-2 whitespace-nowrap rounded px-3 py-3 text-center font-semibold text-gray-600 hover:bg-gray-200 lg:px-6"
    >
        <i class="pi pi-home" />
        {{ activeFacility.shortName }}
    </nuxt-link>
</template>

<script setup lang="ts">
import { useRouteLink } from '~/composables/routing'
import { useActiveFacility } from '~/composables/use-active-facility'
import NuxtLink from '#app/components/nuxt-link'

const activeFacility = useActiveFacility()
const { facilityHomeLink } = useRouteLink()
</script>
